.countdown-page-wrapper {
  background-color: #050102;
  height: 100%;
  display: flex;
  flex-direction: row;
  color: #f6e3b2;
  overflow: hidden;
}

.countdown-page-left {
  height: 100vh;
  width: 50vw;
  overflow: hidden;
  /* Hide image overflow */
  /* background-image: url('../images/album-artwork-square.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

.countdown-page-left img {
  height: 100%;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  user-select: none;
}

.countdown-page-left .album-visual {
  height: 100%;
  position: relative;
  left: 100px;
  bottom: 0;
}

.countdown-page-right {
  height: 100vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-right: 5%;
}

.countdown-out {
  width: 100%;
  text-align: center;
  font-size: 42px;
  font-weight: 500;
  margin-bottom: 24px;
  letter-spacing: 0.1rem;
}

.countdown-box {
  border: 1px solid #f6e3b2;
  padding: 12px 16px;
  border-radius: 8px;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.countdown-unit {
  width: 25%;
}

.countdown-number {
  font-size: 68px;
  font-weight: 500;
}

.countdown-text {
  font-size: 13px;
  padding: 0px 0px 12px 0px;
  letter-spacing: 0.1rem;
}

.scroll-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  color: #f6e3b2;
  font-size: 15px;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
  padding-top: 70px;
}

.scroll-button:hover {
  opacity: .5;
}

.scroll-button span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 2px solid #f6e3b2;
  border-bottom: 2px solid #f6e3b2;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: arrow-fade 1.5s infinite;
  animation: arrow-fade 1.5s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes arrow-fade {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@keyframes arrow-fade {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1024px) {
  .countdown-page-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .countdown-page-left {
    width: 100vw;
    height: 90%;
    order: 2;
  }
  .countdown-out {
    display: none;
  }
  .countdown-page-right {
    width: 100vw;
    height: 10%;
    order: 1;
    z-index: 1;
    padding: 0px;
  }
  .countdown-box {
    border: 0px;
    width: 100%;
    padding: 8% 0px 0px 0px;
  }
  .countdown-unit {
    width: 21%;
  }
}

@media screen and (max-width: 768px) {
  .countdown-number {
    font-size: 48px;
  }
  .countdown-text {
    font-size: 10px;
    padding: 0px;
  }
}