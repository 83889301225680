.sign-in {
    padding-top: 24px;
    width: 100vw;
}

.sign-in .title {
    margin-bottom: 48px;
    color: #f6e3b2;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    user-select: none;
}

.sign-in .warnings {
    margin: 24px 24px 0px 24px;
    color: #f6e3b2;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
}

.sign-in .warnings div {
    max-width: 552px;
}

.sign-in .description {
    margin: 24px 24px 0px 24px;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
}

.sign-in .description div {
    width: 100%;
    max-width: 552px;
    text-align: left;
    margin-bottom: 8px;
}

.sign-in .description div:first-child {
    border-top: 1px solid #212121;
    padding-top: 12px;
}

@media screen and (max-width: 599px) {
    .sign-in .title {
        margin-bottom: 24px;
        font-size: 28px;
    }
    .sign-in .warnings {
        margin: 0px 24px 0px 24px;
        font-size: 14px;
    }
}