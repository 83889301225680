.credits {
    padding-top: 24px;
    width: 100vw;
}

.credits .title {
    margin-bottom: 24px;
    color: #f6e3b2;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    user-select: none;
}

.credits .credits-box-wrapper {
    margin: 0px 24px;
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
}

.credits .credits-box-wrapper .credits-box {
    margin-bottom: 24px;
    padding: 16px 24px 24px 24px;
    border-radius: 8px;
    border: 1px solid #f6e3b2;
    max-width: 600px;
    width: 100%;
    color: #757575;
    font-size: 16px;
}

.credits .credits-box-wrapper .credits-box {
    color: #757575;
}

.credits .credits-box-wrapper .credits-box a {
    color: #757575;
    transition: all 0.25s;
}

.credits .credits-box-wrapper .credits-box a:hover {
    color: white;
}

.credits .credits-box-wrapper .credits-box div {
    margin-top: 8px;
}

.credits .credits-box-wrapper .credits-box .credits-box-name {
    color: white;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 8px;
}

@media screen and (max-width: 599px) {
    .credits .title {
        font-size: 28px;
        margin-bottom: 24px;
    }
    .credits .credits-box-wrapper {
        margin: 0px 16px;
    }
    .credits .credits-box-wrapper .credits-box {
        margin-bottom: 16px;
        padding: 8px 16px 16px 16px;
        font-size: 14px;
    }
    .credits .credits-box-wrapper .credits-box .credits-box-name {
        font-size: 20px;
    }
}