.welcome-page-wrapper {
    width: 100vw;
    height: 100%;
    color: #f6e3b2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.welcome-page-wrapper div {
    font-size: 23px;
    margin-bottom: 16px;
}

.open-menu-button {
    color: white;
    border: 1px solid white;
    background-color: rgb(255, 145, 0);
    font-size: 18px;
    font-family: 'Rajdhani', sans-serif;
    padding: 8px 16px;
}