.song-item-square {
    width: 100%;
    margin-bottom: 20px;
}

.song-item-square img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.song-item-square img:hover {
    cursor: pointer;
}

.song-item-square .info a {
    color: #757575;
    font-weight: 500;
    text-decoration: none;
    display: inline-flex;
}

.song-item-square .info a:hover {
    color: #f6e3b2;
}

.song-item-square .info .title {
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.song-item-square .info .title {
    cursor: pointer;
}

.song-item-square .info .artist {
    color: #757575;
}

.song-item-square .actions {
    width: 100%;
    color: #757575;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

.song-item-square .actions .spotify-icon {
    color: #1DB954;
    font-size: 21px;
    margin-right: 8px;
}

.song-item-square .actions .spotify-icon:hover {
    cursor: pointer;
}

.song-item-square .actions .soundcloud-icon {
    color: #ff5500;
    font-size: 21px;
}

.song-item-square .actions .soundcloud-icon:hover {
    cursor: pointer;
}

.song-item-square .actions .likes {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.song-item-square .actions .heart {
    color: #757575;
    font-size: 20px;
    margin-left: 8px;
    transition: all 0.25s;
}

.song-item-square .actions .heart:hover {
    color: #f44336;
    cursor: pointer;
}

.song-item-square .actions .heart-solid {
    color: #f44336;
    font-size: 20px;
    margin-left: 8px;
    transition: all 0.25s;
}

.song-item-square .actions .heart-solid:hover {
    filter: opacity(70%);
    cursor: pointer;
}

@media screen and (max-width: 599px) {
    .song-item-square {
        margin-bottom: 8px;
    }
    .song-item-square .info .title {
        font-size: 18px;
    }
    .song-item-square .info .artist {
        font-size: 15px;
    }
    .song-item-square .actions .spotify-icon {
        font-size: 19px;
    }
    .song-item-square .actions .souncloud-icon {
        font-size: 20px;
    }
    .song-item-square .actions .heart {
        font-size: 19px;
    }
    .song-item-square .actions .heart-solid {
        font-size: 19px;
    }
}