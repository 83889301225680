.phoenix-loader-wrapper {
    background-color: #050102;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.phoenix-loader-wrapper iframe {
    width: 380px;
    height: 380px;
    pointer-events: none;
    user-select: none;
}

@media screen and (max-width: 767px) {
    .phoenix-loader-wrapper iframe {
        width: 240px;
        height: 240px;
    }
}