.profile {
    padding-top: 24px;
    width: 100vw;
}

.profile .title {
    margin-bottom: 24px;
    color: #f6e3b2;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    user-select: none;
}

.profile .image {
    text-align: center;
}

.profile .image img {
    box-shadow: 0px 0px 12px 1px rgba(255, 255, 255, 0.3);
    height: 128px;
    border-radius: 8px;
    margin: 8px 0px 16px 0px;
}

.profile .form {
    padding: 0px 24px;
}

.profile .action {
    margin-top: 24px;
    text-align: center;
}

@media screen and (max-width: 599px) {
    .profile .title {
        font-size: 28px;
        margin-bottom: 16px;
    }
}