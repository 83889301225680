@import url(https://fonts.googleapis.com/css?family=Rajdhani:100,200,300,400,500,600,700,800,900);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  font-family: 'Rajdhani', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #050102;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.phoenix-loader-wrapper {
    background-color: #050102;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.phoenix-loader-wrapper iframe {
    width: 380px;
    height: 380px;
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

@media screen and (max-width: 767px) {
    .phoenix-loader-wrapper iframe {
        width: 240px;
        height: 240px;
    }
}
.countdown-page-wrapper {
  background-color: #050102;
  height: 100%;
  display: flex;
  flex-direction: row;
  color: #f6e3b2;
  overflow: hidden;
}

.countdown-page-left {
  height: 100vh;
  width: 50vw;
  overflow: hidden;
  /* Hide image overflow */
  /* background-image: url('../images/album-artwork-square.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

.countdown-page-left img {
  height: 100%;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.countdown-page-left .album-visual {
  height: 100%;
  position: relative;
  left: 100px;
  bottom: 0;
}

.countdown-page-right {
  height: 100vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-right: 5%;
}

.countdown-out {
  width: 100%;
  text-align: center;
  font-size: 42px;
  font-weight: 500;
  margin-bottom: 24px;
  letter-spacing: 0.1rem;
}

.countdown-box {
  border: 1px solid #f6e3b2;
  padding: 12px 16px;
  border-radius: 8px;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.countdown-unit {
  width: 25%;
}

.countdown-number {
  font-size: 68px;
  font-weight: 500;
}

.countdown-text {
  font-size: 13px;
  padding: 0px 0px 12px 0px;
  letter-spacing: 0.1rem;
}

.scroll-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  color: #f6e3b2;
  font-size: 15px;
  letter-spacing: .1em;
  text-decoration: none;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  padding-top: 70px;
}

.scroll-button:hover {
  opacity: .5;
}

.scroll-button span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 2px solid #f6e3b2;
  border-bottom: 2px solid #f6e3b2;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: arrow-fade 1.5s infinite;
  animation: arrow-fade 1.5s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes arrow-fade {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@keyframes arrow-fade {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
            transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
            transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media screen and (max-width: 1024px) {
  .countdown-page-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .countdown-page-left {
    width: 100vw;
    height: 90%;
    order: 2;
  }
  .countdown-out {
    display: none;
  }
  .countdown-page-right {
    width: 100vw;
    height: 10%;
    order: 1;
    z-index: 1;
    padding: 0px;
  }
  .countdown-box {
    border: 0px;
    width: 100%;
    padding: 8% 0px 0px 0px;
  }
  .countdown-unit {
    width: 21%;
  }
}

@media screen and (max-width: 768px) {
  .countdown-number {
    font-size: 48px;
  }
  .countdown-text {
    font-size: 10px;
    padding: 0px;
  }
}
.fullscreen-menu-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100vw;
    background-color: #050102;
    display: flex;
    flex-direction: row;
}

.menu-left {
    height: 100%;
    width: 70vw;
    overflow: hidden;
}

.menu-left .menu-video {
    position: relative;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    min-width: 100%;
    min-height: 100%;
    -webkit-filter: invert(0%) brightness(90%) grayscale(80%);
            filter: invert(0%) brightness(90%) grayscale(80%);
}

.menu-right {
    height: 100%;
    width: 30vw;
    color: #f6e3b2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 64px;
    list-style: none;
    margin: 0px;
}

.menu-right li {
    font-size: 35px;
    font-weight: 600;
    margin: 12px 0px;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.menu-right li:hover {
    cursor: pointer;
    text-decoration: underline;
    -webkit-transform: translate(20px, 0px);
            transform: translate(20px, 0px);
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.menu-right a {
    color: #f6e3b2;
    text-decoration: none;
}

.menu-right .action {
    font-size: 23px;
    position: absolute;
    top: 12px;
    right: 50%;
    -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0);
}

.menu-right .action:hover {
    cursor: pointer;
}

.menu-right .menu-footer {
    font-size: 14px;
    position: absolute;
    bottom: 0;
}

@media screen and (max-width: 959px) {
    .fullscreen-menu-wrapper {
        display: inherit;
    }
    .menu-left {
        position: absolute;
        width: 100vw;
    }
    .menu-left .menu-video {
        -webkit-filter: invert(10%) brightness(35%) grayscale(80%);
                filter: invert(10%) brightness(35%) grayscale(80%);
    }
    .menu-right {
        position: absolute;
        width: 100vw;
        font-size: 28px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 0px;
    }
    .menu-right li {
        font-size: 32px;
        margin: 8px 0px;
    }
    .menu-right li:hover {
        -webkit-transform: translate(0px, 0px);
                transform: translate(0px, 0px);
    }
    .menu-right .action {
        font-size: 20px;
    }
    .menu-right .menu-footer {
        font-size: 12px;
    }
}
.welcome-page-wrapper {
    width: 100vw;
    height: 100%;
    color: #f6e3b2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.welcome-page-wrapper div {
    font-size: 23px;
    margin-bottom: 16px;
}

.open-menu-button {
    color: white;
    border: 1px solid white;
    background-color: rgb(255, 145, 0);
    font-size: 18px;
    font-family: 'Rajdhani', sans-serif;
    padding: 8px 16px;
}
.credits {
    padding-top: 24px;
    width: 100vw;
}

.credits .title {
    margin-bottom: 24px;
    color: #f6e3b2;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.credits .credits-box-wrapper {
    margin: 0px 24px;
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
}

.credits .credits-box-wrapper .credits-box {
    margin-bottom: 24px;
    padding: 16px 24px 24px 24px;
    border-radius: 8px;
    border: 1px solid #f6e3b2;
    max-width: 600px;
    width: 100%;
    color: #757575;
    font-size: 16px;
}

.credits .credits-box-wrapper .credits-box {
    color: #757575;
}

.credits .credits-box-wrapper .credits-box a {
    color: #757575;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}

.credits .credits-box-wrapper .credits-box a:hover {
    color: white;
}

.credits .credits-box-wrapper .credits-box div {
    margin-top: 8px;
}

.credits .credits-box-wrapper .credits-box .credits-box-name {
    color: white;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 8px;
}

@media screen and (max-width: 599px) {
    .credits .title {
        font-size: 28px;
        margin-bottom: 24px;
    }
    .credits .credits-box-wrapper {
        margin: 0px 16px;
    }
    .credits .credits-box-wrapper .credits-box {
        margin-bottom: 16px;
        padding: 8px 16px 16px 16px;
        font-size: 14px;
    }
    .credits .credits-box-wrapper .credits-box .credits-box-name {
        font-size: 20px;
    }
}
.profile {
    padding-top: 24px;
    width: 100vw;
}

.profile .title {
    margin-bottom: 24px;
    color: #f6e3b2;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.profile .image {
    text-align: center;
}

.profile .image img {
    box-shadow: 0px 0px 12px 1px rgba(255, 255, 255, 0.3);
    height: 128px;
    border-radius: 8px;
    margin: 8px 0px 16px 0px;
}

.profile .form {
    padding: 0px 24px;
}

.profile .action {
    margin-top: 24px;
    text-align: center;
}

@media screen and (max-width: 599px) {
    .profile .title {
        font-size: 28px;
        margin-bottom: 16px;
    }
}
.sign-in {
    padding-top: 24px;
    width: 100vw;
}

.sign-in .title {
    margin-bottom: 48px;
    color: #f6e3b2;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.sign-in .warnings {
    margin: 24px 24px 0px 24px;
    color: #f6e3b2;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.sign-in .warnings div {
    max-width: 552px;
}

.sign-in .description {
    margin: 24px 24px 0px 24px;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.sign-in .description div {
    width: 100%;
    max-width: 552px;
    text-align: left;
    margin-bottom: 8px;
}

.sign-in .description div:first-child {
    border-top: 1px solid #212121;
    padding-top: 12px;
}

@media screen and (max-width: 599px) {
    .sign-in .title {
        margin-bottom: 24px;
        font-size: 28px;
    }
    .sign-in .warnings {
        margin: 0px 24px 0px 24px;
        font-size: 14px;
    }
}
.firebaseui-container {
    background: transparent;
    margin-bottom: 15px;
    min-height: 150px;
    width: 100%;
    max-width: 600px !important;
    padding: 0px 0px 16px 0px;
    border-radius: 8px;
    box-shadow: none;
    font-family: 'Rajdhani', sans-serif !important;
}

.firebaseui-container.firebaseui-page-provider-sign-in {
    background: transparent;
    box-shadow: none;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
}

.firebaseui-container.firebaseui-id-page-callback {
    background: transparent;
    box-shadow: none;
    margin-top: 40px;
    height: 84px;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
}

.firebaseui-card-header {
    display: none;
}

.firebaseui-subtitle, .firebaseui-text {
    color: rgba(255, 255, 255, 0.87);
}

.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
    background: rgba(0, 0, 0, 0.1);
}

.firebaseui-id-dismiss-info-bar {
    display: block;
}

.firebaseui-info-bar {
    padding: 16px !important;
    border: 0;
    border-radius: 8px;
    bottom: 10%;
    background-color: black !important;
    border: 1px solid #f6e3b2 !important;
}

.firebaseui-info-bar .firebaseui-info-bar-message {
    font-size: 18px;
    color: white;
}

/* Auth Option Button */

.firebaseui-list-item button {
    border-radius: 4px !important;
    height: 46px;
    border: 1px solid;
}

/* PHONE MODAL STYLING */

.firebaseui-container.firebaseui-id-page-phone-sign-in-start {
    background-color: transparent;
}

.firebaseui-container.firebaseui-id-page-phone-sign-in-finish {
    background-color: transparent;
}

.firebaseui-phone-number .mdl-button {
    color: white;
    height: 40px;
    border-radius: 4px;
}

.firebaseui-phone-number .mdl-button:hover {
    background-color: #212121;
}

.firebaseui-country-selector {
    margin: 20px 12px 20px 0px !important;
}

.firebaseui-phone-number .firebaseui-flag {
    -webkit-transform: translate(0px, 2px);
            transform: translate(0px, 2px);
}

.firebaseui-label.mdl-textfield__label {
    color: #757575;
    font-size: 18px;
    top: 28px;
    left: 8px;
}

.mdl-textfield--floating-label.is-dirty .mdl-textfield__label, .mdl-textfield--floating-label.is-focused .mdl-textfield__label {
    color: #757575;
    font-size: 14px !important;
    top: 2px !important;
}

.mdl-textfield__label:after {
    background-color: transparent !important;
}

input.firebaseui-input {
    background-color: #212121;
    height: 31px;
    border-radius: 4px;
    color: white;
    font-size: 18px;
}

input.firebaseui-input::-webkit-input-placeholder {
    color: white;
}

input.firebaseui-input::-moz-placeholder {
    color: white;
}

input.firebaseui-input:-ms-input-placeholder {
    color: white;
}

input.firebaseui-input::-ms-input-placeholder {
    color: white;
}

input.firebaseui-input::placeholder {
    color: white;
}

.mdl-textfield__input {
    padding: 4px 0px 4px 8px !important;
    width: calc(100% - 8px) !important;
    color: white;
}

/* Error message */

.firebaseui-phone-number-error {
    margin-left: 110px !important;
}

.firebaseui-error {
    font-size: 13px !important;
}

/* Cancel Button */

.mdl-button--primary.mdl-button--primary {
    color: #f6e3b2 !important;
    height: 38px;
    border-radius: 4px;
}

.mdl-button--primary.mdl-button--primary:hover {
    background-color: #212121 !important;
}

/* Verify Button */

.mdl-button--raised.mdl-button--colored {
    color: #212121 !important;
    background-color: #f6e3b2 !important;
    height: 38px;
    border-radius: 4px;
}

.mdl-button--raised.mdl-button--colored:hover {
    background-color: #f6e3b2 !important;
}

/* Bottom footer */

.firebaseui-card-footer .firebaseui-tos {
    padding-top: 12px;
    font-size: 13px;
    line-height: 18px;
}

/* Progress Bar */

.mdl-progress {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    height: 4px;
}

.mdl-progress .bar1 {
    background-color: #f6e3b2 !important;
}

/* Submit code confirmation text */

.firebaseui-resend-container .firebaseui-id-resend-countdown {
    color: #757575;
}

.firebaseui-text {
    color: white !important;
}

.firebaseui-text .firebaseui-change-phone-number-link {
    display: inline;
    display: initial;
}

/* Link */

.firebaseui-link {
    color: #f6e3b2 !important;
    font-size: 16px !important;
}

/* Dialog */

.mdl-dialog {
    background-color: black !important;
    color: white !important;
    border: 1px solid #f6e3b2 !important;
    border-radius: 8px;
}

.firebaseui-list-box-dialog .firebaseui-list-box-actions .firebaseui-list-box-dialog-button .firebaseui-list-box-label-wrapper {
    color: white;
}
.sticky-year-divider {
    background-color: #050102;
    color: white;
    font-size: 41px;
    font-weight: 700;
    padding: 8px 0px;
    border-bottom: 1px solid #212121;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.sticky-year-divider .action {
    color: #757575;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    padding: 6px 16px;
    border: 1px solid #757575;
    border-radius: 4px;
    -webkit-transition: all .25s;
    transition: all .25s;
}

.sticky-year-divider .action:hover {
    color: #f6e3b2;
    cursor: pointer;
    border: 1px solid #f6e3b2;
}

@media screen and (max-width: 599px) {
    .sticky-year-divider {
        font-size: 36px;
        padding: 4px 0px;
    }
}
.song-item-full {
    display: flex;
    flex-direction: row;
    margin: 24px 0px;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}

.song-item-full img {
    width: 136px;
    height: 136px;
    border-radius: 8px;
}

.song-item-full img:hover {
    cursor: pointer;
}

.song-item-full .song-item-full-right {
    width: 100%;
    margin-left: 8px;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.song-item-full .song-item-full-right:hover {
    border-radius: 8px;
    cursor: pointer;
    background-color: #212121;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}

.song-item-full .song-item-full-right .top .title {
    color: white;
    font-size: 21px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.song-item-full .song-item-full-right .top .artist {
    width: 100%;
    color: #757575;
}

.song-item-full .song-item-full-right .top .album {
    color: #757575;
}

.song-item-full .song-item-full-right .top a {
    color: #757575;
    font-weight: 500;
    text-decoration: none;
    display: inline-flex;
}

.song-item-full .song-item-full-right .top a:hover {
    color: #f6e3b2;
}

.song-item-full .song-item-full-right .bottom {
    width: 100%;
    color: #757575;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.song-item-full .song-item-full-right .bottom .spotify-icon {
    color: #1DB954;
    font-size: 23px;
    margin-right: 12px;
}

.song-item-full .song-item-full-right .bottom .spotify-icon:hover {
    cursor: pointer;
}

.song-item-full .song-item-full-right .bottom .soundcloud-icon {
    color: #ff5500;
    font-size: 23px;
}

.song-item-full .song-item-full-right .bottom .soundcloud-icon:hover {
    cursor: pointer;
}

.song-item-full .song-item-full-right .bottom .likes {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.song-item-full .song-item-full-right .bottom .heart {
    color: #757575;
    font-size: 21px;
    margin-left: 8px;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}

.song-item-full .song-item-full-right .bottom .heart:hover {
    color: #f44336;
    cursor: pointer;
}

.song-item-full .song-item-full-right .bottom .heart-solid {
    color: #f44336;
    font-size: 21px;
    margin-left: 8px;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}

.song-item-full .song-item-full-right .bottom .heart-soid:hover {
    -webkit-filter: opacity(70%);
            filter: opacity(70%);
    cursor: pointer;
}

@media screen and (max-width: 599px) {
    .song-item-full {
        margin: 16px 0px;
    }
    .song-item-full img {
        width: 96px;
        height: 96px;
    }
    .song-item-full .song-item-full-right {
        margin-left: 0px;
        padding: 4px 0px 4px 8px;
    }
    .song-item-full .song-item-full-right:hover {
        background-color: transparent;
    }
    .song-item-full .song-item-full-right .top .title {
        width: calc(100vw - 140px);
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 18px;
    }
    .song-item-full .song-item-full-right .top .artist {
        font-size: 15px;
    }
    .song-item-full .song-item-full-right .top .album {
        display: none;
    }
    .song-item-full .song-item-full-right .bottom .spotify-icon {
        font-size: 19px;
    }
    .song-item-full .song-item-full-right .bottom .soundcloud-icon {
        font-size: 20px;
    }
    .song-item-full .song-item-full-right .bottom .likes {
        font-size: 16px;
    }
    .song-item-full .song-item-full-right .bottom .heart {
        font-size: 19px;
    }
    .song-item-full .song-item-full-right .bottom .heart-solid {
        font-size: 19px;
    }
}
.song-item-square {
    width: 100%;
    margin-bottom: 20px;
}

.song-item-square img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.song-item-square img:hover {
    cursor: pointer;
}

.song-item-square .info a {
    color: #757575;
    font-weight: 500;
    text-decoration: none;
    display: inline-flex;
}

.song-item-square .info a:hover {
    color: #f6e3b2;
}

.song-item-square .info .title {
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.song-item-square .info .title {
    cursor: pointer;
}

.song-item-square .info .artist {
    color: #757575;
}

.song-item-square .actions {
    width: 100%;
    color: #757575;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

.song-item-square .actions .spotify-icon {
    color: #1DB954;
    font-size: 21px;
    margin-right: 8px;
}

.song-item-square .actions .spotify-icon:hover {
    cursor: pointer;
}

.song-item-square .actions .soundcloud-icon {
    color: #ff5500;
    font-size: 21px;
}

.song-item-square .actions .soundcloud-icon:hover {
    cursor: pointer;
}

.song-item-square .actions .likes {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.song-item-square .actions .heart {
    color: #757575;
    font-size: 20px;
    margin-left: 8px;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}

.song-item-square .actions .heart:hover {
    color: #f44336;
    cursor: pointer;
}

.song-item-square .actions .heart-solid {
    color: #f44336;
    font-size: 20px;
    margin-left: 8px;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}

.song-item-square .actions .heart-solid:hover {
    -webkit-filter: opacity(70%);
            filter: opacity(70%);
    cursor: pointer;
}

@media screen and (max-width: 599px) {
    .song-item-square {
        margin-bottom: 8px;
    }
    .song-item-square .info .title {
        font-size: 18px;
    }
    .song-item-square .info .artist {
        font-size: 15px;
    }
    .song-item-square .actions .spotify-icon {
        font-size: 19px;
    }
    .song-item-square .actions .souncloud-icon {
        font-size: 20px;
    }
    .song-item-square .actions .heart {
        font-size: 19px;
    }
    .song-item-square .actions .heart-solid {
        font-size: 19px;
    }
}
.audio-viewer {
    width: 100vw;
    height: 100vh;
    /* max-height: 100vh; */
    overflow-x: hidden;
}

.audio-viewer .collapse {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.audio-viewer .collapse .collapse-button {
    font-size: 30px;
    color: #757575;
}

.audio-viewer .collapse .collapse-button:hover {
    cursor: pointer;
}

.audio-viewer .main {
    width: 100%;
}

.audio-viewer .main .artwork {
    width: 100%;
    height: calc(100vw - 72px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.audio-viewer .main .artwork .full {
    width: calc(100vw - 72px);
    height: calc(100vw - 72px);
    border-radius: 8px;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}

.audio-viewer .main .artwork .min {
    width: calc(100vw - 128px);
    height: calc(100vw - 128px);
    border-radius: 8px;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}

.audio-viewer .main .slider {
    width: 100%;
    padding: 4px 36px 0px 36px;
}

.audio-viewer .main .time {
    padding: 0px 36px;
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.audio-viewer .main .name {
    padding: 12px 36px 0px 36px;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.audio-viewer .main .artists {
    padding: 0px 36px;
    text-align: center;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.audio-viewer .main .actions {
    padding: 28px 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.audio-viewer .main .actions .play-button {
    color: white;
    font-size: 45px;
}

.audio-viewer .main .actions .backward-button {
    color: white;
    font-size: 32px;
    /* padding-left: 16px; */
}

.audio-viewer .main .actions .forward-button {
    color: white;
    font-size: 32px;
    /* padding-right: 16px; */
}

.audio-viewer .main .actions .shuffle-button {
    color: #353535;
    font-size: 20px;
}

.audio-viewer .main .actions .like-button {
    color: #353535;
    font-size: 20px;
}

.audio-viewer .main .lyrics {
    text-align: center;
    font-size: 14px;
    color: #353535;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.audio-viewer .main .lyrics .lyrics-icon {
    font-size: 11px;
}

@media screen and (min-width: 600px) {
    .audio-viewer .main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .audio-viewer .main .artwork {
        width: 100%;
        height: calc(65vh);
    }
    .audio-viewer .main .slider {
        padding: 4px 0px 8px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .audio-viewer .main .time {
        width: 65vh;
    }
    .audio-viewer .main .artwork .full {
        width: calc(65vh);
        height: calc(65vh);
    }
    .audio-viewer .main .artwork .min {
        width: calc(60vh);
        height: calc(60vh);
    }
    .audio-viewer .main .actions {
        width: 65vh;
    }
}
.audio-controller-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 56px;
    background-color: #181818;
}

.audio-controller-wrapper .mobile {
    height: 100%;
}

.audio-controller-wrapper .mobile .actions {
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.audio-controller-wrapper .mobile .actions .song-info {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.audio-controller-wrapper .mobile .actions .song-info .title {
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin-top: 2px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.audio-controller-wrapper .mobile .actions .song-info .artists {
    color: #757575;
    font-size: 13px;
    font-weight: 500;
    margin-top: -2px;
}

.audio-controller-wrapper .mobile .actions .play-button {
    color: #757575;
    font-size: 23px;
    margin: 0px 12px;
}

.audio-controller-wrapper .mobile .actions .heart-button {
    color: #757575;
    font-size: 23px;
    margin: 0px 12px;
}

.audio-controller-wrapper .mobile .actions .heart-solid-button {
    color: white;
    font-size: 23px;
    margin: 0px 12px;
}
.leaderboard {
    min-height: 100vh;
    padding: 24px;
}

.leaderboard .title {
    margin-bottom: 24px;
    color: #f6e3b2;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.leaderboard .table .header {
    color: white;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid white;
    display: flex;
    flex-direction: row;
    padding-bottom: 8px;
}

.leaderboard .table .header .rank {
    width: 10%;
    padding-left: 8px;
}

.leaderboard .table .header .name {
    width: 80%;
}

.leaderboard .table .header .likes {
    width: 10%;
    text-align: right;
    padding-right: 8px;
}

.leaderboard .table .row {
    color: #424242;
    border-bottom: 1px solid #212121;
    display: flex;
    font-weight: 500;
    flex-direction: row;
    align-content: center;
    padding: 8px 0px 6px 0px;
}

.leaderboard .table .row:hover {
    background-color: #181818;
}

.leaderboard .table .top {
    color: #f6e3b2;
}

.leaderboard .table .top .name span {
    color: #757575;
    font-size: 15px;
    font-weight: 400;
}

.leaderboard .table .row .rank {
    width: 10%;
    padding-left: 8px;
}

.leaderboard .table .row .name {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.leaderboard .table .row .name span {
    font-size: 15px;
    font-weight: 400;
}

.leaderboard .table .row .likes {
    width: 10%;
    text-align: right;
    padding-right: 8px;
}

.leaderboard .collapse-button {
    position: fixed;
    bottom: 8px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    font-size: 25px;
}

.leaderboard .collapse-button:hover {
    cursor: pointer;
}

@media screen and (max-width: 599px) {
    .leaderboard {
        padding: 16px;
    }
    .leaderboard .title {
        margin-bottom: 16px;
        font-size: 28px;
    }
    .leaderboard .table .header {
        font-size: 18px;
    }
    .leaderboard .table .header .rank {
        width: 8%;
    }
    .leaderboard .table .header .name {
        width: 80%;
    }
    .leaderboard .table .header .likes {
        width: 12%;
    }
    .leaderboard .table .row {
        font-size: 15px;
    }
    .leaderboard .table .row .rank {
        width: 8%;
    }
    .leaderboard .table .row .likes {
        width: 12%;
    }
}
.songs-wrapper {
    padding-bottom: 56px;
}

.songs-loader {
    height: 80vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #e65100;
    font-size: 17px;
}

.songs-loader img {
    width: 164px;
}

.songs-action-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 24px 0px;
}

.songs-action-bar .question-icon {
    color: #757575;
    font-size: 16px;
    margin-left: 16px;
    -webkit-transition: all .25s;
    transition: all .25s;
}

.songs-action-bar .question-icon:hover {
    color: #f6e3b2;
    cursor: pointer;
}

.songs-action-bar .action-icon {
    color: #757575;
    font-size: 20px;
    margin-left: 16px;
    -webkit-transition: all .25s;
    transition: all .25s;
}

.songs-action-bar .action-icon:hover {
    color: #f6e3b2;
    cursor: pointer;
}

.songs-action-bar .action-icon-selected {
    color: #f6e3b2;
}

.songs-action-bar .menu-icon {
    color: #757575;
    font-size: 20px;
    margin-left: 16px;
    -webkit-transition: all .25s;
    transition: all .25s;
}

.songs-action-bar .menu-icon:hover {
    color: #f6e3b2;
    cursor: pointer;
}

@media screen and (max-width: 1279px) {
    .songs-wrapper {
        padding: 0px 48px 56px 48px;
    }
}

@media screen and (max-width: 959px) {
    .songs-wrapper {
        padding: 0px 24px 56px 24px;
    }
}

@media screen and (max-width: 599px) {
    .songs-wrapper {
        padding: 0px 16px 56px 16px;
    }
    .songs-action-bar {
        padding: 10px 0px 16px 0px;
    }
}
.not-found {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.not-found .not-found-text {
    padding: 24px;
    text-align: center;
    font-size: 54px;
    font-weight: 500;
    color: white;
    z-index: 1;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.not-found a {
    font-size: 30px;
    color: #f6e3b2;
    text-decoration: none;
    z-index: 1;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.not-found a:hover {
    -webkit-filter: opacity(50%);
            filter: opacity(50%);
}

.not-found iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .not-found .not-found-text {
        font-size: 28px;
    }
}
