.leaderboard {
    min-height: 100vh;
    padding: 24px;
}

.leaderboard .title {
    margin-bottom: 24px;
    color: #f6e3b2;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    user-select: none;
}

.leaderboard .table .header {
    color: white;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid white;
    display: flex;
    flex-direction: row;
    padding-bottom: 8px;
}

.leaderboard .table .header .rank {
    width: 10%;
    padding-left: 8px;
}

.leaderboard .table .header .name {
    width: 80%;
}

.leaderboard .table .header .likes {
    width: 10%;
    text-align: right;
    padding-right: 8px;
}

.leaderboard .table .row {
    color: #424242;
    border-bottom: 1px solid #212121;
    display: flex;
    font-weight: 500;
    flex-direction: row;
    align-content: center;
    padding: 8px 0px 6px 0px;
}

.leaderboard .table .row:hover {
    background-color: #181818;
}

.leaderboard .table .top {
    color: #f6e3b2;
}

.leaderboard .table .top .name span {
    color: #757575;
    font-size: 15px;
    font-weight: 400;
}

.leaderboard .table .row .rank {
    width: 10%;
    padding-left: 8px;
}

.leaderboard .table .row .name {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.leaderboard .table .row .name span {
    font-size: 15px;
    font-weight: 400;
}

.leaderboard .table .row .likes {
    width: 10%;
    text-align: right;
    padding-right: 8px;
}

.leaderboard .collapse-button {
    position: fixed;
    bottom: 8px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 25px;
}

.leaderboard .collapse-button:hover {
    cursor: pointer;
}

@media screen and (max-width: 599px) {
    .leaderboard {
        padding: 16px;
    }
    .leaderboard .title {
        margin-bottom: 16px;
        font-size: 28px;
    }
    .leaderboard .table .header {
        font-size: 18px;
    }
    .leaderboard .table .header .rank {
        width: 8%;
    }
    .leaderboard .table .header .name {
        width: 80%;
    }
    .leaderboard .table .header .likes {
        width: 12%;
    }
    .leaderboard .table .row {
        font-size: 15px;
    }
    .leaderboard .table .row .rank {
        width: 8%;
    }
    .leaderboard .table .row .likes {
        width: 12%;
    }
}