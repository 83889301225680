.not-found {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.not-found .not-found-text {
    padding: 24px;
    text-align: center;
    font-size: 54px;
    font-weight: 500;
    color: white;
    z-index: 1;
    user-select: none;
}

.not-found a {
    font-size: 30px;
    color: #f6e3b2;
    text-decoration: none;
    z-index: 1;
    user-select: none;
    transition: all 0.5s;
}

.not-found a:hover {
    filter: opacity(50%);
}

.not-found iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .not-found .not-found-text {
        font-size: 28px;
    }
}