.firebaseui-container {
    background: transparent;
    margin-bottom: 15px;
    min-height: 150px;
    width: 100%;
    max-width: 600px !important;
    padding: 0px 0px 16px 0px;
    border-radius: 8px;
    box-shadow: none;
    font-family: 'Rajdhani', sans-serif !important;
}

.firebaseui-container.firebaseui-page-provider-sign-in {
    background: transparent;
    box-shadow: none;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
}

.firebaseui-container.firebaseui-id-page-callback {
    background: transparent;
    box-shadow: none;
    margin-top: 40px;
    height: 84px;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
}

.firebaseui-card-header {
    display: none;
}

.firebaseui-subtitle, .firebaseui-text {
    color: rgba(255, 255, 255, 0.87);
}

.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
    background: rgba(0, 0, 0, 0.1);
}

.firebaseui-id-dismiss-info-bar {
    display: block;
}

.firebaseui-info-bar {
    padding: 16px !important;
    border: 0;
    border-radius: 8px;
    bottom: 10%;
    background-color: black !important;
    border: 1px solid #f6e3b2 !important;
}

.firebaseui-info-bar .firebaseui-info-bar-message {
    font-size: 18px;
    color: white;
}

/* Auth Option Button */

.firebaseui-list-item button {
    border-radius: 4px !important;
    height: 46px;
    border: 1px solid;
}

/* PHONE MODAL STYLING */

.firebaseui-container.firebaseui-id-page-phone-sign-in-start {
    background-color: transparent;
}

.firebaseui-container.firebaseui-id-page-phone-sign-in-finish {
    background-color: transparent;
}

.firebaseui-phone-number .mdl-button {
    color: white;
    height: 40px;
    border-radius: 4px;
}

.firebaseui-phone-number .mdl-button:hover {
    background-color: #212121;
}

.firebaseui-country-selector {
    margin: 20px 12px 20px 0px !important;
}

.firebaseui-phone-number .firebaseui-flag {
    transform: translate(0px, 2px);
}

.firebaseui-label.mdl-textfield__label {
    color: #757575;
    font-size: 18px;
    top: 28px;
    left: 8px;
}

.mdl-textfield--floating-label.is-dirty .mdl-textfield__label, .mdl-textfield--floating-label.is-focused .mdl-textfield__label {
    color: #757575;
    font-size: 14px !important;
    top: 2px !important;
}

.mdl-textfield__label:after {
    background-color: transparent !important;
}

input.firebaseui-input {
    background-color: #212121;
    height: 31px;
    border-radius: 4px;
    color: white;
    font-size: 18px;
}

input.firebaseui-input::placeholder {
    color: white;
}

.mdl-textfield__input {
    padding: 4px 0px 4px 8px !important;
    width: calc(100% - 8px) !important;
    color: white;
}

/* Error message */

.firebaseui-phone-number-error {
    margin-left: 110px !important;
}

.firebaseui-error {
    font-size: 13px !important;
}

/* Cancel Button */

.mdl-button--primary.mdl-button--primary {
    color: #f6e3b2 !important;
    height: 38px;
    border-radius: 4px;
}

.mdl-button--primary.mdl-button--primary:hover {
    background-color: #212121 !important;
}

/* Verify Button */

.mdl-button--raised.mdl-button--colored {
    color: #212121 !important;
    background-color: #f6e3b2 !important;
    height: 38px;
    border-radius: 4px;
}

.mdl-button--raised.mdl-button--colored:hover {
    background-color: #f6e3b2 !important;
}

/* Bottom footer */

.firebaseui-card-footer .firebaseui-tos {
    padding-top: 12px;
    font-size: 13px;
    line-height: 18px;
}

/* Progress Bar */

.mdl-progress {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    height: 4px;
}

.mdl-progress .bar1 {
    background-color: #f6e3b2 !important;
}

/* Submit code confirmation text */

.firebaseui-resend-container .firebaseui-id-resend-countdown {
    color: #757575;
}

.firebaseui-text {
    color: white !important;
}

.firebaseui-text .firebaseui-change-phone-number-link {
    display: initial;
}

/* Link */

.firebaseui-link {
    color: #f6e3b2 !important;
    font-size: 16px !important;
}

/* Dialog */

.mdl-dialog {
    background-color: black !important;
    color: white !important;
    border: 1px solid #f6e3b2 !important;
    border-radius: 8px;
}

.firebaseui-list-box-dialog .firebaseui-list-box-actions .firebaseui-list-box-dialog-button .firebaseui-list-box-label-wrapper {
    color: white;
}