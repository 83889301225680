.songs-wrapper {
    padding-bottom: 56px;
}

.songs-loader {
    height: 80vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #e65100;
    font-size: 17px;
}

.songs-loader img {
    width: 164px;
}

.songs-action-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 24px 0px;
}

.songs-action-bar .question-icon {
    color: #757575;
    font-size: 16px;
    margin-left: 16px;
    transition: all .25s;
}

.songs-action-bar .question-icon:hover {
    color: #f6e3b2;
    cursor: pointer;
}

.songs-action-bar .action-icon {
    color: #757575;
    font-size: 20px;
    margin-left: 16px;
    transition: all .25s;
}

.songs-action-bar .action-icon:hover {
    color: #f6e3b2;
    cursor: pointer;
}

.songs-action-bar .action-icon-selected {
    color: #f6e3b2;
}

.songs-action-bar .menu-icon {
    color: #757575;
    font-size: 20px;
    margin-left: 16px;
    transition: all .25s;
}

.songs-action-bar .menu-icon:hover {
    color: #f6e3b2;
    cursor: pointer;
}

@media screen and (max-width: 1279px) {
    .songs-wrapper {
        padding: 0px 48px 56px 48px;
    }
}

@media screen and (max-width: 959px) {
    .songs-wrapper {
        padding: 0px 24px 56px 24px;
    }
}

@media screen and (max-width: 599px) {
    .songs-wrapper {
        padding: 0px 16px 56px 16px;
    }
    .songs-action-bar {
        padding: 10px 0px 16px 0px;
    }
}