.fullscreen-menu-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100vw;
    background-color: #050102;
    display: flex;
    flex-direction: row;
}

.menu-left {
    height: 100%;
    width: 70vw;
    overflow: hidden;
}

.menu-left .menu-video {
    position: relative;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    min-width: 100%;
    min-height: 100%;
    filter: invert(0%) brightness(90%) grayscale(80%);
}

.menu-right {
    height: 100%;
    width: 30vw;
    color: #f6e3b2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 64px;
    list-style: none;
    margin: 0px;
}

.menu-right li {
    font-size: 35px;
    font-weight: 600;
    margin: 12px 0px;
    transition: all 0.25s ease;
    user-select: none;
}

.menu-right li:hover {
    cursor: pointer;
    text-decoration: underline;
    transform: translate(20px, 0px);
    transition: all 0.25s ease;
}

.menu-right a {
    color: #f6e3b2;
    text-decoration: none;
}

.menu-right .action {
    font-size: 23px;
    position: absolute;
    top: 12px;
    right: 50%;
    transform: translate(50%, 0);
}

.menu-right .action:hover {
    cursor: pointer;
}

.menu-right .menu-footer {
    font-size: 14px;
    position: absolute;
    bottom: 0;
}

@media screen and (max-width: 959px) {
    .fullscreen-menu-wrapper {
        display: inherit;
    }
    .menu-left {
        position: absolute;
        width: 100vw;
    }
    .menu-left .menu-video {
        filter: invert(10%) brightness(35%) grayscale(80%);
    }
    .menu-right {
        position: absolute;
        width: 100vw;
        font-size: 28px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 0px;
    }
    .menu-right li {
        font-size: 32px;
        margin: 8px 0px;
    }
    .menu-right li:hover {
        transform: translate(0px, 0px);
    }
    .menu-right .action {
        font-size: 20px;
    }
    .menu-right .menu-footer {
        font-size: 12px;
    }
}